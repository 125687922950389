.wind-marker {
    display: flex;
    flex-direction: row;
    flex: 1;
    color: black;
    background-color: rgba(255, 255, 255, 0.6);
    border: solid 0px rgba(255, 255, 255, 0.6);
    font-weight: 900;
    font-size: 16px;
    padding: 4px;
    top: -8px;
    border-radius: 5px;
}

.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-direction-row {
    flex-direction: row;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.center-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.info-window-icon {
    font-size: 35px;
    opacity: 0.6;
    padding: 5px 2px 0px 2px;
}
