@font-face {
    font-family: 'Wind';
    src: url('./Wind.eot');
    src: url('./Wind.eot') format('embedded-opentype'),
        url('./Wind.ttf') format('truetype'),
        url('./Wind.woff') format('woff'),
        url('./Wind.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-wind-"],
[class*=" icon-wind-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Wind' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-wind-speed:before {
    content: "\e900";
}

.icon-wind-wind:before {
    content: "\e901";
}

.icon-wind-chevron-up:before {
    content: "\e902";
}