@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* inter var - latin */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url("/public/fonts/inter-var-latin.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  .cursor-newtab {
    cursor: url("/images/new-tab.png") 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-sans text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-sans text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-sans text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-sans text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-sans text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-sans text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  hr {
    @apply border-gray-200 dark:border-gray-600;
  }

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}

/* Components */

.dashboard-header {
  @apply h-fit bg-white flex p-4 border-b-2 border-gray-200 dark:bg-darkPrimary dark:border-gray-700;
}

.sidebar-container {
  @apply absolute w-20 h-full bg-primary-200 dark:bg-darkPanel justify-items-center py-4 top-0 bottom-0 z-[51];
}

.sidebar-menu {
  @apply w-14 h-14 m-auto mb-4 cursor-pointer items-center justify-items-center content-center transition-colors duration-300 rounded-lg;
}

.sidebar-menu-selected {
  @apply bg-white dark:bg-darkSecondary;
}

.sidebar-toggle-button {
  @apply bg-white shadow-md h-16 rounded-r-lg m-auto border-l border-gray-200 items-center justify-center flex w-8 dark:bg-darkSecondary dark:border-darkSecondary;
}

.side-panel {
  @apply h-full shadow-md bg-white dark:bg-darkSecondary dark:text-white;
}

.place-autocomplete-container {
  @apply bg-gray2 dark:bg-darkSecondary rounded-full w-full flex items-center justify-center h-fit shadow-md px-4;
}

.toolbar {
  @apply w-full h-10 bg-white/50 backdrop-blur-sm flex items-center justify-start px-4 absolute top-0 z-40 dark:bg-white/5 dark:text-white;
}

.toolbar-menu {
  @apply cursor-pointer select-none;
}

.toolbar-dropdown {
  @apply absolute top-5 right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-md min-w-32 dark:bg-darkTertiary dark:border-darkSecondary;
}

.toolbar-dropdown li {
  @apply px-4 py-2 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600;
}

.user-dropdown {
  @apply w-64 bg-white rounded-md flex-col shadow-md border-t border-gray-200 mt-4 dark:bg-darkSecondary dark:border-darkSecondary;
}

.user-dropdown li {
  @apply flex items-center content-center p-4 cursor-pointer hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700;
}

.notification-dropdown {
  @apply w-80 h-[70vh] overflow-auto bg-white rounded-md flex-col shadow-md border-t border-gray-200 mt-4 dark:bg-darkSecondary dark:border-darkSecondary dark:text-white;
}

.popup-menu {
  @apply bg-white rounded-md shadow-md w-96 dark:bg-darkQuaternary dark:text-white;
}

.popup-menu li {
  @apply p-2 cursor-pointer hover:text-gray-300 dark:hover:text-gray-400;
}

/* Form Input */

.label {
  @apply block ml-2 text-sm font-medium text-gray-900 dark:text-white;
}

.th {
  @apply text-start text-sm font-medium text-gray-900 dark:text-white py-1;
}

.input-text {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-darkTertiary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.input-text-disabled {
  @apply bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-darkTertiary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.input-rounded {
  @apply bg-darkNavy border border-lightNavy text-white sm:text-sm rounded-full focus:ring-primary-600 focus:border-primary-600 block w-full px-4 py-3 dark:bg-darkTertiary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.input-transparent {
  @apply bg-transparent p-2 border-transparent focus:border-none focus:ring-0 focus:outline-none w-full;
}

.input-select {
  @apply bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full border border-gray-300 dark:bg-darkTertiary dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.input-checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-darkTertiary dark:border-gray-600 cursor-pointer;
}

.input-radio {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500;
}

.select-dropdown {
  @apply z-10 bg-white divide-y divide-gray-100 rounded-lg shadow-sm w-full border-2 border-blue-200 dark:bg-darkQuaternary dark:border-gray-600 dark:divide-gray-600;
}

.select-dropdown li {
  @apply w-full block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer select-none;
}

.dropdown-autocomplete {
  @apply p-1 border-2 border-blue-200 mb-1 rounded-lg;
}

.dropdown-ul {
  @apply select-dropdown py-2 text-sm text-gray-700 dark:text-gray-200 max-h-[250px] overflow-auto;
}

.dropdown-li {
  @apply px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer;
}

/* Buttons */

.btn-primary {
  @apply text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 text-center dark:bg-darkSecondary dark:hover:bg-primary-600 dark:focus:ring-primary-800;
}

.btn-secondary {
  @apply text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm px-5 text-center dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-800;
}

.btn-danger {
  @apply text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm px-5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800;
}

.btn-light {
  @apply text-gray-900 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-full text-sm px-5 text-center dark:bg-gray-200 dark:hover:bg-gray-300 dark:focus:ring-gray-800;
}

.btn-primary-dark {
  @apply text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 text-center dark:bg-darkQuaternary dark:hover:bg-darkTertiary dark:focus:ring-gray-600;
}

.btn-icon {
  @apply w-11 h-11 items-center justify-items-center;
}

.icon-btn {
  @apply fill-gray5 m-auto dark:fill-gray-400;
}

.btn-rounded-primary {
  @apply text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-2 py-1 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800;
}

.btn-rounded-focused {
  @apply text-white bg-purple-500 hover:bg-purple-600 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg px-2 py-1 me-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800;
}

.btn-rounded-secondary {
  @apply text-gray-900 bg-white border border-blue-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg px-2 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700;
}

.btn-rounded-danger {
  @apply focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg px-2 py-1 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;
}

/* Label/Typography */

.label-blue {
  @apply text-labelBlueDark dark:text-white;
}

.label-secondary {
  @apply text-gray4 dark:text-white;
}

/* Modal */
.modal-container {
  @apply bg-white rounded-lg p-4 w-fit h-fit min-h-[300px] min-w-[500px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 dark:bg-darkSecondary dark:text-white;
}

.modal-container tr {
  @apply border border-gray-200 dark:border-gray-600;
}

/* MISC */

.classic-tab {
  @apply text-sm font-medium text-center text-gray-500 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-500 mb-2;
}

.classic-tab ul {
  @apply flex flex-wrap -mb-px;
}

.classic-tab li {
  @apply me-2;
}

.classic-tab-item {
  @apply inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300;
}

.classic-tab-item-active {
  @apply inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500;
}

.tab-rounded {
  @apply bg-gray-200 rounded-lg p-2 h-12 flex dark:bg-darkPrimary;
}

.tab-rounded span {
  @apply grow p-2 rounded-lg text-center cursor-pointer hover:bg-gray-100 ease-in-out transition-all duration-300 dark:text-white dark:hover:bg-darkPanel;
}

.tab-rounded .tab-active {
  @apply bg-white font-medium dark:bg-darkSecondary;
}

.new-badge {
  @apply bg-gray-200 text-sm me-2 px-2.5 py-0.5 rounded-full text-gray-600 dark:bg-gray-600 dark:text-white;
}

.new-badge-info {
  @apply bg-yellow-300 text-yellow-500 text-sm;
}

.new-badge-success {
  @apply bg-green-200 text-green-500;
}

.new-badge-warning {
  @apply bg-orange-200 text-orange-500;
}

.new-badge-danger {
  @apply bg-red-200 text-red-500;
}

.rounded-radio-container {
  @apply items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg sm:flex dark:bg-darkTertiary dark:border-gray-600 dark:text-white py-2;
}

.rounded-container {
  @apply bg-white border-gray2 border-2 rounded-lg p-2 dark:bg-darkSecondary dark:border-gray-600 dark:text-white;
}

.rounded-container-np {
  @apply bg-white border-gray2 border-2 rounded-lg dark:bg-darkTertiary dark:border-gray-600 dark:text-white dark:border;
}

.rounded-top-container {
  @apply bg-white border-gray2 border-2 rounded-t-lg p-2;
}

.rounded-container-orange {
  @apply bg-white border-orange-400 border rounded-lg p-2 dark:bg-darkTertiary;
}

.tooltip {
  @apply absolute rounded shadow-lg p-1 bg-gray-600 text-white z-[99];
}

.mapboxgl-ctrl-top-right {
  @apply mt-10;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23888%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23888%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
}

.mapboxgl-style-switcher {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg%3E%3Cpath fill='%23363434' d='M30.765,7.262L19.222,0.747c-1.571-0.887-4.271-0.888-5.845,0L1.834,7.262C0.951,7.761,0.466,8.459,0.466,9.229s0.486,1.468,1.368,1.966l11.543,6.516c0.786,0.444,1.824,0.688,2.922,0.688c1.099,0,2.137-0.245,2.923-0.688l11.543-6.516c0.883-0.498,1.369-1.196,1.369-1.966S31.647,7.761,30.765,7.262z M30.239,10.265l-11.543,6.516c-1.238,0.699-3.556,0.7-4.794,0L2.359,10.265C1.835,9.969,1.535,9.591,1.535,9.229c0-0.363,0.3-0.74,0.824-1.037l11.543-6.516c0.619-0.35,1.493-0.55,2.396-0.55c0.904,0,1.778,0.201,2.397,0.55l11.543,6.516c0.524,0.296,0.825,0.674,0.825,1.037C31.064,9.591,30.764,9.969,30.239,10.265z'/%3E%3Cpath fill='%23363434' d='M30.765,14.099c-0.256-0.145-0.582-0.055-0.729,0.203c-0.145,0.257-0.054,0.583,0.203,0.728c0.524,0.296,0.825,0.674,0.825,1.036s-0.301,0.74-0.825,1.036l-11.543,6.516c-1.298,0.733-3.494,0.734-4.794,0L2.359,17.102c-0.524-0.296-0.824-0.674-0.824-1.036s0.3-0.74,0.824-1.036c0.257-0.145,0.348-0.471,0.203-0.728c-0.146-0.258-0.473-0.349-0.729-0.203c-0.883,0.498-1.368,1.197-1.368,1.967s0.485,1.469,1.368,1.967l11.543,6.516c0.819,0.462,1.871,0.694,2.922,0.694c1.052,0,2.104-0.231,2.923-0.694l11.543-6.516c0.883-0.498,1.369-1.197,1.369-1.967S31.647,14.597,30.765,14.099z'/%3E%3Cpath fill='%23363434' d='M30.765,20.804c-0.256-0.145-0.582-0.055-0.729,0.203c-0.145,0.257-0.054,0.583,0.203,0.728c0.524,0.296,0.825,0.674,0.825,1.036c0,0.362-0.301,0.74-0.825,1.037l-11.543,6.516c-1.298,0.733-3.494,0.734-4.794,0L2.359,23.807c-0.524-0.296-0.824-0.674-0.824-1.037c0-0.362,0.3-0.74,0.824-1.036c0.257-0.145,0.348-0.471,0.203-0.728c-0.146-0.257-0.473-0.349-0.729-0.203C0.952,21.302,0.466,22,0.466,22.771s0.485,1.469,1.368,1.967l11.543,6.516c0.819,0.462,1.871,0.694,2.922,0.694c1.052,0,2.104-0.231,2.923-0.694l11.543-6.516c0.883-0.499,1.369-1.197,1.369-1.967S31.647,21.302,30.765,20.804z'/%3E%3C/g%3E%3C/svg%3E") !important;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(128, 128, 128);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
